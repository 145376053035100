// import Vue from 'vue'
import { mapState } from "vuex";

// const mixinsComputed = Vue.config.optionMergeStrategies.computed
// const mixinsMethods = Vue.config.optionMergeStrategies.methods

const mixin = {
    computed: {
        ...mapState({
            layoutMode: state => state.app.layout,
            navTheme: state => state.app.theme,
            primaryColor: state => state.app.color,
            colorWeak: state => state.app.weak,
            multipage: state => state.app.multipage, //多页签设置
            fixedHeader: state => state.app.fixedHeader,
            fixSiderbar: state => state.app.fixSiderbar,
            contentWidth: state => state.app.contentWidth,
            autoHideHeader: state => state.app.autoHideHeader,
            sidebarOpened: state => state.app.sidebar.opened
        })
    }
}

const mixinDevice = {
        computed: {
            ...mapState({
                device: state => state.app.device,
            })
        },
        methods: {
            isMobile() {
                return this.device === 'mobile'
            },
            isDesktop() {
                return this.device === 'desktop'
            }
        }
    }
    //分页相关
    const mixinPagination = {
        data() {
            return {
                //初始化分页条件
                queryParam: { pageNum: 1, pageSize: 10 },
                dataList: [],//列表数据
                //页码配置
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize:10,
                    pageSizeOptions: ['10', '20', '40', '80'],
                    // showQuickJumper: true,
                    showSizeChanger: true,
                    // onShowSizeChange: (current, pageSize) => this.onSizeChange(current, pageSize),
                },
                loading: true,
            }
        },
        methods: {
            //分页
            paginationFun: function (res) {
                // console.log(res);
                this.loading = false
                this.dataList = res.result.list
                this.pagination.total = res.result.total
                this.pagination.pageSize = res.result.pageSize
                this.pagination.showTotal = function () {
                    return '总数：' + res.result.total + '条'
                }
            },
    
            //重置
            resetSearchForm: function () {
                this.queryParam = {
                    pageNum: 1,
                }
                this.loadList({})
            },
            // onSizeChange(current, pageSize) {
            //     console.log(current, pageSize, '111');
    
            //     this.pagination.current = 1
            //     this.pagination.pageNum = 1
            //     this.queryParam.pageSize = pageSize
            //     console.log(this.pagination);
            //     this.loadList({})
            //     // this.pagination.pageNo = 1
            //     // this.pagination.pageSize = pageSize
            //     // this.getList()
            // },
            //改变分页数
            handleTableChange: function (pagination) {
                    this.pagination.current = pagination.current
                    this.queryParam.pageNum = pagination.current
                    this.queryParam.pageSize = pagination.pageSize
                    this.loadList({})
            }
            
        }
    }
export { mixin, mixinDevice, mixinPagination }